import { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { UisBars, UisTimesCircle } from '@iconscout/react-unicons-solid'
import Typography from '@mui/material/Typography';
import SearchBar from "./filterbar/SearchBar";
import { setFilter, clearFilter } from "./features/PetitionFilterSlice"
import { useDispatch } from "react-redux";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function CustomizedDialogs(props) {
    const startYear = 1999
    const endDate = (new Date()).getFullYear()
    const { isOpen=true } = props
    const [open, setOpen] = useState(isOpen)
    const [selectedYear, setSelectedYear] = useState(endDate)

    const handleClose = () => {
        setOpen(false)
        props.onClose()
    };

    const dispatch = useDispatch()

    const setYearFilter = (year)=>{
        dispatch(setFilter({
            key: props.filterId,
            filter: "year",
            value: year
        }))
    }

    const yearsCount = Number.parseInt((endDate - startYear) + 1)
    const yearLinks = [...(Array(yearsCount).keys())].map((value)=>{
        const year = value * 1 + startYear
        return <a onClick={()=>{ setYearFilter(year); setSelectedYear(year) }} className={["font-Montserrat", "text-[9pt] cursor-pointer", "year", "filter-item", (year == selectedYear)? "item-active" : ""].join(" ")}>{year}</a>
    })

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                PaperProps={{
                    style: { borderRadius: "20px", padding: "20px" }
                }}
            >
               
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <UisTimesCircle />
                </IconButton>
                <DialogContent>
                    <div className="mt-[20px] w-full transition-all transition-[2000] filterItem h-[50px]">
                        <SearchBar fid={props.filterId} onClick={()=>{ }} />
                    </div>
                    
                    <p className="mt-[25px] text-[10pt] font-semibold font-Montserrat text-center">Filter by Year</p>

                    <div className="mt-[15px] flex flex-wrap justify-center gap-[10px]">
                       { yearLinks }
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </Fragment>
    );
}