import { useState, useEffect } from "react"
import SearchFilterBar from "../components/filterbar/SearchFilterBar"
import "../styles/Petition.css"
import Charts from "../components/charts/ChartsPages"
import Table from "../components/Table"
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query"
import { useSelector } from "react-redux"

const queryClient = new QueryClient()
const PETITIONS_URL = process.env.REACT_APP_API_HOST + "/petitions/filter"

const PetitionsPage = ()=>{
    
    const [isTable, setIsTable] = useState(false)
    const petitionFilterParams = useSelector((state)=>state.filter.value.petitions)
    const [queryParams, setQueryParams] = useState(petitionFilterParams)
    const [query, setPetitionsQuery] = useState("")

    const switchContent = (isTable)=>{
        setIsTable(isTable)
    }

    useEffect(()=>{
        let params = JSON.stringify(petitionFilterParams)
        if(params != queryParams){
            let q = ""
            for(let key in petitionFilterParams){
                if(key == "court"){
                    continue
                }else if(key == "search"){
                    q += `q=${petitionFilterParams[key]}&`
                    continue
                }
                q += `${key}=${petitionFilterParams[key]}&`
            }
            setQueryParams(params)
            setPetitionsQuery(q)
            if(q.length <= 0){
                return
            }
        }
    })

    // charts content
    const charts = 
    <>
        <Charts filterQuery={query}/>
    </>
    
    // petition table content
    const petitionsTable = (
        <div className="mt-[50px] flex justify-center">
            <Table queryKey="petitions" url={PETITIONS_URL} queryParams={query} />
        </div>
    )

    // cannot display anything else when the data for this component is loading

    return (
       <>
            <div className="Petition py-[40px] px-[5%]">
                <SearchFilterBar fid="petitions" toggleContent={switchContent} isTable={isTable} />
                {(isTable)? petitionsTable : charts}
            </div>
       </>
    );
}

export default PetitionsPage;